<template>
  <div>

    <b-tabs
        v-if="expt && expt.type == 'Liaison form'"
    >
      <b-tab active
             :title="capitalize($t('expenseAccounts')) + ' ('+expt.expensesClaims.length + ')'">

        <suppliers-invoices-view
            :allow-add="false"
            :allow-add-by-upload="false"
            :allow-edit="false"
            :allow-remove="false"

            @reloadSuppliersInvoices="fetchExpensesClaims"
        />
      </b-tab>

      <b-tab :title="capitalize($t('mileageAllowances')) + ' ('+expt.events.length + ')'">

        <events-view

            @reloadEvents="fetchEvents"
        />

      </b-tab>

    </b-tabs>

    <suppliers-invoices-view
        v-else-if="expt && expt.type == 'Suppliers invoices export'"
        :allow-add="false"
        :allow-add-by-upload="false"
        :allow-edit="false"
        :allow-remove="false"

        @reloadSuppliersInvoices="fetchSuppliersInvoices"
    />

    <customer-invoices-view
        v-else-if="expt && expt.type == 'Customers invoices export'"
        :allow-add="false"
        :allow-add-by-upload="false"
        :allow-edit="false"
        :allow-remove="false"
        :allow-validate="false"
        :allow-archivate="false"

        @reloadCustomersInvoices="fetchCustomersInvoices"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import SuppliersInvoicesView from '../../../components/views/SuppliersInvoicesView.vue'
import CustomerInvoicesView from '../../../components/views/CustomerInvoicesView.vue'
import EventsView from '../../../components/views/EventsView.vue'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    SuppliersInvoicesView,
    CustomerInvoicesView,
    EventsView
  },
  props: {
    expt: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    console.log("ok")
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.expt, val => {
      // console.log(val)
      fetchEntities(val.type)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchIncomingInvoices2, fetchOutgoingInvoices2, fetchEvents2 } = useAPI()

    const fetchEntities = (type) => {
      if (type) {
        if (type == 'Liaison form') {
          fetchExpensesClaims()
          fetchEvents()
        } else if (type == 'Suppliers invoices export') {
          fetchSuppliersInvoices()
        } else if (type == 'Customers invoices export') {
          fetchCustomersInvoices()
        }
      }
    }

    const fetchEvents = (payload = null) => {
      if (payload == null) {
        payload = {
          page: 1,
          perPage: 25,
          sortColumn: null,
          sortOrder: null,
          searchCriteria: [{
            column_name: 'export',
            comparator: '=',
            search_text: props.expt.id
          }]
        }
      }

      if (!payload.searchCriteria.some(sc => sc.column_name == 'export')) {
        payload.searchCriteria.push({
          column_name: 'export',
          comparator: '=',
          search_text: props.expt.id
        })
      } else {
        fetchEvents2(payload)
      }
    }

    const fetchExpensesClaims = (payload = null) => {
      if (payload == null) {
        payload = {
          page: 1,
          perPage: 25,
          sortColumn: null,
          sortOrder: null,
          searchCriteria: [{
            column_name: 'expensesClaimsExport',
            comparator: '=',
            search_text: props.expt.id
          }]
        }
      }

      if (!payload.searchCriteria.some(sc => sc.column_name == 'expensesClaimsExport')) {
        payload.searchCriteria.push({
          column_name: 'expensesClaimsExport',
          comparator: '=',
          search_text: props.expt.id
        })
      } else {
        fetchIncomingInvoices2(payload)
      }
    }

    const fetchSuppliersInvoices = (payload = null) => {
      if (payload == null) {
        payload = {
          page: 1,
          perPage: 25,
          sortColumn: null,
          sortOrder: null,
          searchCriteria: [{
            column_name: 'export',
            comparator: '=',
            search_text: props.expt.id
          }]
        }
      }

      if (!payload.searchCriteria.some(sc => sc.column_name == 'export')) {
        payload.searchCriteria.push({
          column_name: 'export',
          comparator: '=',
          search_text: props.expt.id
        })
      } else {
        fetchIncomingInvoices2(payload)
      }
    }

    const fetchCustomersInvoices = (payload = null) => {
      if (payload == null) {
        payload = {
          page: 1,
          perPage: 25,
          sortColumn: null,
          sortOrder: null,
          searchCriteria: [{
            column_name: 'export',
            comparator: '=',
            search_text: props.expt.id
          }]
        }
      }

      if (!payload.searchCriteria.some(sc => sc.column_name == 'export')) {
        payload.searchCriteria.push({
          column_name: 'export',
          comparator: '=',
          search_text: props.expt.id
        })
      } else {
        fetchOutgoingInvoices2(payload)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchEntities(props.expt.type)

    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
      fetchExpensesClaims,
      fetchSuppliersInvoices,
      fetchCustomersInvoices,
      fetchEvents,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>