var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isReady)?_c('base-template',{ref:"baseTemplate",attrs:{"title":_vm.$tc('export',2),"items":_vm.exports,"items-context":_vm.exportsContext,"available-filters":_vm.store.getters['export/getAvailableFilters'],"available-global-amounts":_vm.store.getters['export/getAvailableGlobalAmounts'],"payload":_vm.payload,"module":_vm.module,"is-list-view-action-column-enabled":true,"allow-view-windows":true,"is-view-windows-ready":_vm.displayedExport!=null},on:{"update:payload":function($event){_vm.payload=$event},"showDetails":_vm.showDetails},scopedSlots:_vm._u([{key:"listView_cell_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(_vm.moment(item.date).format('MMMM YYYY')))+" ")]}},{key:"listView_cell__createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(_vm.moment(item._createdAt).format('DD MMMM YYYY HH:mm')))+" ")]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [_c('button-download',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('Download')),expression:"$t('Download')",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.downloadByDocument(item.document)}}})]}},{key:"leftViewWindow",fn:function(ref){
var item = ref.item;
return [_c('export-card',{attrs:{"expt":item,"is-downloadable":false,"is-viewable":true},on:{"click":function($event){return _vm.showDetails(item)},"view":function($event){return _vm.showDetails(item)}}})]}},{key:"viewWindowTitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.moment(_vm.displayedExport.date).format('MMMM YYYY'))+" ")]},proxy:true},{key:"viewWindowButtons",fn:function(){return undefined},proxy:true},{key:"windowView",fn:function(){return [_c('export-view',{attrs:{"expt":_vm.displayedExport}})]},proxy:true}],null,false,3762126115)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }