<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('export',2)"
        :items="exports"
        :items-context="exportsContext"
        :available-filters="store.getters['export/getAvailableFilters']"
        :available-global-amounts="store.getters['export/getAvailableGlobalAmounts']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="true"
        :is-view-windows-ready="displayedExport!=null"

        @showDetails="showDetails"
    >
      <template #listView_cell_date="{item}">
        {{ capitalize(moment(item.date).format('MMMM YYYY')) }}
      </template>
      <template #listView_cell__createdAt="{item}">
        {{ capitalize(moment(item._createdAt).format('DD MMMM YYYY HH:mm')) }}
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Download-->
        <button-download
            @click.native.stop="downloadByDocument(item.document)"
            v-b-tooltip.hover.left="$t('Download')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

      </template>

      <template #leftViewWindow="{item}">
        <export-card
            :expt="item"
            :is-downloadable="false"
            :is-viewable="true"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ moment(displayedExport.date).format('MMMM YYYY') }}
      </template>
      <template #viewWindowButtons>
      </template>
      <template #windowView>
        <export-view :expt="displayedExport"/>
        <!--        <supplier-invoice-view :supplier-invoice="displayedSupplierInvoice"/>-->
      </template>
    </base-template>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../utils/filter'
import { downloadByDocument }   from '../../../utils/utils'

import useAPI from '../../../utils/useAPI'
import store  from '../../../store'
import moment from 'moment'

import BaseTemplate   from '../../../components/base3/Base.vue'
import ExportCard     from '../../../components/card/Export.vue'
import ExportView     from './ExportView.vue'
import buttonDownload from '../../../components/button/Download.vue'

export default {
  components: {
    buttonDownload,
    ExportView,
    ExportCard,
    BaseTemplate,
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('exports')
    const payload = ref({})
    const isReady = ref(false)

    const displayedExport = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { exports, exportsContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchExports(val)
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchExports,
    } = useAPI()

    const showDetails = (exportLocal) => {
      if (exportLocal != null) {
        store.dispatch('export/getExport', exportLocal.id)
            .then(response => {
              displayedExport.value = response
            })
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            listGlobalAmount: currentView.listGlobalAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,
      moment,

      // Data
      module,
      isReady,
      payload,
      displayedExport,
      downloadByDocument,

      // Computed
      exports,
      exportsContext,

      // Methods
      showDetails,
    }
  },
  data () {
    return {}
  },
  computed: {
    store () {
      return store
    }
  },
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>