<template>
  <generic-content
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      :isDownloadable="isDownloadable"
      :isViewable="isViewable"
      :is-selected="isSelected"
      @click="isParentMethodPriority?$emit('click'):downloadByDocument($event.document)"
      @download="downloadByDocument($event.document)"
      @toRoute="isParentMethodPriority?$emit('toRoute'):''"
      @view="$emit('view')"
      @edit="$emit('edit')"
      @pay="$emit('pay')"
      @delete="$emit('delete')"
  >
    <template #bodyContent>
      <h6 v-if="expt.document" class="transaction-title text-truncate">
        {{ expt.document.name }}
      </h6>
      <div class="d-flex justify-content-between text-truncate">
        <small class="text-truncate">
          <i>{{ $t(expt.type) }}</i>
        </small>
        <small>{{ capitalize(moment(expt.date).format('MMMM YYYY')) }}</small>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericContent from './Content'
import moment from 'moment'
import {downloadByDocument} from '../../../utils/utils'
import { capitalize } from '../../../utils/filter'

export default {
  components: {
    GenericContent
  },
  props: {
    expt: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,
      downloadByDocument,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>