<template>
  <div>

    <base-template
        title="Events"
        itemView="list"
        :text-button-add="$t('Add')"
        :columns="columns"
        :filters="filters"
        :items="events2"
        :context="events2Context"
        :isActionColumnEnabled="false"
        :open-view-window.sync="openViewWindow"
        :allow-add="allowAdd"

        @fetch="$emit('reloadEvents', $event)"
        @selectItem="showDetails"
        @addItem="eventsModalShow=true"
    >
      <template #bottomTitle>
        <!--        <i v-if="eventsContext.custom_fields && displayCustomFields && !allowAdd && !allowAddByUpload">-->
        <!--          {{ currency(eventsContext.custom_fields.excludingExpensesAndReimbursements) }}-->
        <!--          {{ $t('excludingExpensesAndReimbursements') }}-->
        <!--        </i>-->
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <event-card
            :event="item"
            :is-downloadable="false"
            :is-viewable="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <!--      <template #listView_cell_actions="{item}">-->
      <!--        <div class="text-nowrap">-->
      <!--        </div>-->
      <!--      </template>-->


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <event-card
            :event="item"
            :is-downloadable="false"
            :is-viewable="false"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0].title }}</h4>

          <div>
            <!--            &lt;!&ndash;          Download&ndash;&gt;-->
            <!--            <button-download-->
            <!--                @click.native.stop="downloadEvent(selectedItems[0])"-->
            <!--                v-b-tooltip.hover.left="$t('Download')"-->
            <!--                :withIcon="true"-->
            <!--                :withText="false"-->
            <!--                :withBorder="false"-->
            <!--                size="sm"-->
            <!--            />-->

            <!--            &lt;!&ndash;          Edit &ndash;&gt;-->
            <!--            <button-edit-->
            <!--                v-if="allowEdit"-->
            <!--                @click.native.stop="editEvent(selectedItems[0])"-->
            <!--                v-b-tooltip.hover.left="$t('Edit')"-->
            <!--                :withIcon="true"-->
            <!--                :withText="false"-->
            <!--                :withBorder="false"-->
            <!--                size="sm"-->
            <!--            />-->

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <!--        <event-view-->
        <!--            class="invoice-preview"-->
        <!--            :event="selectedItems[0]"-->
        <!--        />-->


      </template>
    </base-template>

    <!--    &lt;!&ndash; modal event by upload&ndash;&gt;-->
    <!--    <modal-upload-event-->
    <!--        :singleFile.sync="newEventFile"-->
    <!--        :documentType="'event'"-->
    <!--        :title="capitalize($t('event'))"-->
    <!--        :isOpen.sync="eventsByUploadModalShow"-->

    <!--    />-->

    <!--    &lt;!&ndash; modal events&ndash;&gt;-->
    <!--    <modal-events-->
    <!--        :events.sync="currentEvents"-->
    <!--        :title="eventModalTitle"-->
    <!--        :isOpen.sync="eventsModalShow"-->
    <!--        @submitValidated="submitValidatedEventsLocal(currentEvents)"-->
    <!--    />-->

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import BaseTemplate from '../base/Base'
import EventCard from '../card/Event.vue'
import ButtonView from '../button/View'
import ButtonDownload from '../button/Download'
import ButtonEdit from '../button/Edit.vue'
import ButtonRemove from '../button/Remove.vue'
import ButtonClose from '../button/Close'
import EventView from './EventView.vue'
import ModalUploadEvent from '../prompt/Upload'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import store from '../../store'

export default {
  components: {
    BaseTemplate,
    EventCard,
    ButtonView,
    ButtonDownload,
    ButtonEdit,
    ButtonRemove,
    ButtonClose,
    EventView,
    ModalUploadEvent,
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
    allowRemove: {
      type: Boolean,
      default: false
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('date')),
        key: 'startDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.tc('startPlace')),
        key: 'startPlace',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.tc('endPlace')),
        key: 'endPlace',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('distance')),
        key: 'distance',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.tc('employee')),
        key: 'logs',
        sortable: true,
        cell: {
          useFirstLogBy: true
        }
      },

    ])
    const filters = ref([{
      text: capitalize(i18n.tc('distance > 0')),
      key: '_filter_isDistanceNotNull',
      searchCriteria: [{
        column_name: 'distance',
        comparator: '!=',
        search_text: '0',
      }],
      selected: true,
    }])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const currentEvents = ref([])
    const eventModalTitle = ref(i18n.t('NewEvent'))
    const eventsModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { events2, events2Context } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(eventsModalShow, val => {
      if (val == false) {
        currentEvents.value = []
        eventModalTitle.value = i18n.t('NewEvent')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const showDetails = (event) => {
      if (event != null) {
        store.dispatch('event2/getEvent', event.id)
            .then(response => {
              selectedItems.value = [response]
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    // const editEvent = (event) => {
    //   eventModalTitle.value = i18n.t('EditEvent')
    //
    //   store.dispatch('event2/getEvent', event.id)
    //       .then(event => {
    //         currentEvents.value = [event]
    //         eventsModalShow.value = true
    //       })
    // }
    //
    // const submitValidatedEventsLocal = (events) => {
    //   // console.log(JSON.parse(JSON.stringify(events)))
    //   events.forEach(event => {
    //     submitValidatedEvent(event)
    //         .then(response => {
    //           eventsModalShow.value = false
    //           resetEvent()
    //         })
    //   })
    // }
    //
    // const resetEvent = () => {
    //   currentEvents.value = JSON.parse(JSON.stringify([]))
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // resetEvent()

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      eventsModalShow,
      currentEvents,
      eventModalTitle,

      // Computed
      events2,
      events2Context,

      // Methods
      showDetails,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    // deleteEventAlert (event) {
    //   this.$bvModal
    //       .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theEvent') }), {
    //         okVariant: 'danger',
    //         okTitle: this.capitalize(this.$t('delete')),
    //         cancelVariant: 'outline-secondary',
    //         cancelTitle: this.capitalize(this.$t('cancel')),
    //         centered: true,
    //       })
    //       .then(value => {
    //         if (value == true) {
    //           this.removeEvent(event)
    //         }
    //       })
    // }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>